<ion-header>
  <div class="modal-header">
    <ion-toolbar>
      <div class="modal-title">
        <h3>Select a Channel</h3>
      </div>
      <ion-buttons slot="end">
        <ion-button fill="clear" shape="round" class="close-button" (click)="dismiss()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <ion-card class="outline-card" *ngIf="teamConnectedToSlack && !userConnectedToSlack">
    <ng-container *ngIf="!isLoading">
      <div class="admin-bar flex-space-between" style="border-radius: 8px; padding: 0">
        <ion-button class="fake-button" fill="clear" color="dark" (click)="connectUserToSlack()" [disabled]="true">
          <ion-icon name="information-circle-outline" class="padded-icon"></ion-icon>
          Additional permissions are required to see private channels.
        </ion-button>

        <ion-button fill="clear" (click)="connectUserToSlack()">
          <ion-icon name="logo-slack" class="padded-icon"></ion-icon>
          Authorize Slack
        </ion-button>
      </div>
    </ng-container>
  </ion-card>

  <div class="flex-space-between flexed-align-center" *ngIf="teamConnectedToSlack && channels?.length">
    <ion-searchbar
      mode="ios"
      class="search-bar"
      placeholder="Find a channel..."
      showCancelButton="never"
      [(ngModel)]="searchText"
      (ionChange)="handleFilters()"
    ></ion-searchbar>
    <div class="flexed">
      <ion-select
        *ngIf="connectedTeams?.length > 1 && !hideSecondaryTeams"
        [(ngModel)]="selectedTeam"
        (ionChange)="loadChannels()"
        style="font-size: 0.8rem; margin-right: 5px"
      >
        <ion-select-option *ngFor="let connectedTeam of connectedTeams" [value]="connectedTeam">{{
          connectedTeam.name
        }}</ion-select-option>
      </ion-select>
      <ion-button
        color="light"
        shape="round"
        class="btn-small"
        style="margin-right: 10px"
        (click)="changeFilter()"
        [hidden]="hideChannelTypeFilter"
      >
        Showing: {{ filterType === 'shared' ? 'Shared only' : filterType === 'internal' ? 'Internal only' : 'All' }}
        <ion-icon name="chevron-down-outline" class="filter-button-caret"></ion-icon>
      </ion-button>
    </div>
  </div>
</ion-header>

<ion-content>
  <div *ngIf="!teamConnectedToSlack" class="flexed-align-center" style="margin-top: 25px; justify-content: center">
    <ion-spinner name="crescent" *ngIf="isLoading"></ion-spinner>
    <div style="display: flex; flex-direction: column; align-items: center; margin: 15px" *ngIf="!isLoading">
      <div style="font-size: 16px; margin-bottom: 20px" class="admin-bar">
        <span>
          New features have been added that require you to re-authenticate to Slack before connecting additional
          channels.
        </span>
      </div>
      <a (click)="connectTeamToSlack()" [ngClass]="{ 'not-allowed': isConnecting }">
        <img
          alt="Add to Slack"
          height="40"
          width="139"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          srcset="
            https://platform.slack-edge.com/img/add_to_slack.png    1x,
            https://platform.slack-edge.com/img/add_to_slack@2x.png 2x
          "
        />
      </a>
    </div>
  </div>
  <ion-list *ngIf="teamConnectedToSlack && channels?.length" class="no-padding transparent-bg">
    <ion-item *ngFor="let channel of filteredChannels" (click)="selectChannel(channel)" button="true">
      {{ channel.displayName }}
      <ion-icon name="checkmark" slot="end" *ngIf="selectedChannelId === channel.id" style="margin: 0"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>
